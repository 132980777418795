import React, { useState } from 'react';
import '../../styles/global.css';

const options = [
  {
    value: 'Web Development',
    label: 'Front-End',
  },
  {
    value: 'UI design',
    label: 'UI/UX Designer',
  },
  {
    value: 'Project Management',
    label: 'Project Manager',
  },
];


export default function Dropdown() {
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(options[0]);

  const renderedOptions = (options.map((option) => {
    if (option.value === selectedOptions.value) {
      return null;
    }
    return (
      <div
        key={option.value}
        className='item'
        onClick={() => setSelectedOptions(option)}>
        {option.label}
      </div>
    );
  }));
  
  return (
    <div>
      <div className='dropdown'>
        <div className='field'>
          <label onClick={() => setOpen(!open)} className='label'>Choose Type Of Project</label>
          <div className={`field ${open ? 'active' : ''}`}>
            {open && (
              <>
                <div className='text'>{selectedOptions.label}</div>
                <div className='visible transition'>{renderedOptions}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
